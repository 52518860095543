/**
 * WebApp
 *
 * This is the main container for the project.
 */

// Core imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LoadingBar from 'react-redux-loading-bar';
import { cssTransition, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { CloudinaryContext } from 'cloudinary-react';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { styled, withTheme } from '@smooth-ui/core-sc';
import GlobalStylesApp from 'theme/globalStylesApp';

// Data
import injectReducer from '_platform/src/utils/injectReducer';
import injectSaga from '_platform/src/utils/injectSaga';
import {
  cartRequest,
  menuRequest,
  settingsRequest,
  wishlistRequest,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import {
  selectCart,
  selectMenu,
  selectRoutes,
  selectSettings,
  selectSettingsNextClaimingPeriod,
  selectSettingsPointsUOM,
  selectSettingsProgram,
  selectSettingsSalesUOMs,
  selectWishlist,
} from './selectors';
import { SettingsProvider } from './SettingsContext';

// Additional Components/Containers
import { trailingSlashIt } from '_platform/src/utils/utilities';
import LoadAsync from '_platform/src/utils/LoadAsync';
import Routes from './Routes';
import Footer from '../../components/CustomComponents/CustomFooter/Footer';
import LoriFooter from './LoriFooter';
import ScrollToTop from '_platform/src/components/ScrollToTop/ScrollToTop';

const Header = LoadAsync(() =>
  import(
    /* webpackChunkName: "header" */ 'components/CustomComponents/CustomHeader/Header'
  )
);
const HeaderNav = LoadAsync(() =>
  import(
    /* webpackChunkName: "headerNav" */ 'components/CustomComponents/CustomHeaderNav/HeaderNav'
  )
);
const Welcome = LoadAsync(() =>
  import(/* webpackChunkName: "welcome" */ 'containers/Welcome/Welcome')
);

const AppWrapper = styled.div`
  /* for child elements */
  display: flex;
  flex-direction: column;

  /* sticky footer */
  flex: 1 0 auto;
`;

const PageWrapper = styled.div`
  /* for child elements */
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

const ToastifyTransition = cssTransition({
  enter: 'Toastify__slide-enter--top-right',
  exit: 'Toastify__slide-exit--top-right',
  duration: [300, 600],
});

class WebApp extends Component {
  componentDidMount() {
    // If the menu is not present, and the user does not have a stored token,
    // refresh the menu.
    // If the user has a token, the menu will be updated in cDU below via token refresh.
    // Prevents unnecessary initial request
    if (!this.props.menu && !this.props.currentUser.token) {
      this.props.onMenuRequest();
    }
  }

  componentDidUpdate(prevProps) {
    // On change of permissions, request the menu (login, token refresh)
    // Won't run if the user has just logged out (as the permissions prop is missing),
    // so we handle that in the logout saga
    if (
      (this.props.currentUser.permissions &&
        this.props.currentUser.permissions !==
          prevProps.currentUser.permissions) ||
      this.props.currentUser.userId !== prevProps.currentUser.userId
    ) {
      this.props.onMenuRequest(true);

      // Request the application settings
      // Need to be authenticated to access the endpoints
      // Should this change, need to copy to cDM as well, and remove the
      // undefined condition from this one.
      if (this.props.settings.lastUpdated === undefined) {
        this.props.onSettingsRequest();
      }

      // Fetch the Cart
      // Do not skip fetch using `settingsApp.cartDisabled` as the cart details
      // are used for the welcome module
      if (
        this.props.cart === undefined ||
        this.props.cart.lastUpdated === undefined
      ) {
        this.props.onCartRequest();
      }

      // Fetch the Wishlist
      // Can skip fetching via settingsApp.wishlistDisabled
      // We're not checking the settings from API as the request would take too long to fetch, causing needless re-renders.
      // The wishlist button / functionality will be disabled/hidden with either of the settings though.
      if (
        !this.props.theme.settingsApp.wishlistDisabled &&
        (this.props.wishlist === undefined ||
          this.props.wishlist.lastUpdated === undefined)
      ) {
        this.props.onWishlistRequest();
      }
    }
  }

  render() {
    const { theme } = this.props;

    return (
      <React.Fragment>
        <LoadingBar
          style={{
            left: 0,
            height: '4px',
            backgroundColor: (theme && theme.loadingBarColor) || '#f4bd19',
            position: 'fixed',
          }}
          updateTime={350}
        />
        <Helmet
          titleTemplate={`%s - ${theme.settingsApp.siteName}`}
          defaultTitle={theme.settingsApp.siteName}
        />
        <ToastContainer
          autoClose={4000}
          newestOnTop
          position="bottom-right"
          transition={ToastifyTransition}
        />
        <SettingsProvider
          settings={{
            nextClaimingPeriod: this.props.settingsNextClaimingPeriod,
            pointsUOM: this.props.settingsPointsUOM,
            program: this.props.settingsProgram,
            routes: this.props.routes,
            salesUOMs: this.props.settingsSalesUOMs,
            settingsApp: this.props.theme.settingsApp,
          }}
        >
          <CloudinaryContext
            cloudName={theme.settingsApp.cloudinaryCloudName}
            style={{ display: 'flex', flex: '1 0 auto', minHeight: '100%' }} // TODO: Remove this when https://github.com/cloudinary/cloudinary-react/pull/81 is merged/published
          >
            <AppWrapper
              style={{ width: '100%' }} // TODO: Remove this when https://github.com/cloudinary/cloudinary-react/pull/81 is merged/published
            >
              <ScrollToTop>
                {trailingSlashIt(this.props.location.pathname) !==
                  '/login/' && (
                  <Header>
                    <HeaderNav
                      menu={this.props.menu}
                      showLoginInMenu={!this.props.currentUser.userId}
                      showLogoutInMenu={!!this.props.currentUser.userId}
                    />
                  </Header>
                )}
                {/*
              Prevent unnecessary re-renders of the Welcome container, and ensure the user is logged in
              Cart is fetched for every logged in user via componentDidUpdate above
              */}
                {this.props.cart && !this.props.cart.isLoading && (
                  <Welcome cart={this.props.cart} />
                )}

                <PageWrapper className="page-wrapper">
                  <Routes routes={this.props.routes} theme={theme} />
                </PageWrapper>
              </ScrollToTop>
              <Footer siteName={this.props.theme.settingsApp.siteName} />
            </AppWrapper>
          </CloudinaryContext>
        </SettingsProvider>
        <LoriFooter />
        <GlobalStylesApp />
      </React.Fragment>
    );
  }
}

WebApp.propTypes = {
  cart: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  menu: PropTypes.array,
  onCartRequest: PropTypes.func.isRequired,
  onMenuRequest: PropTypes.func.isRequired,
  onLogout: PropTypes.func, // eslint-disable-line react/require-default-props
  // Using the disable line above as react sometimes doesn't pass through the prop pre-first render
  onSettingsRequest: PropTypes.func.isRequired,
  onWishlistRequest: PropTypes.func.isRequired,
  routes: PropTypes.array,
  settings: PropTypes.object,
  settingsNextClaimingPeriod: PropTypes.object,
  settingsPointsUOM: PropTypes.object,
  settingsProgram: PropTypes.object,
  settingsSalesUOMs: PropTypes.array,
  theme: PropTypes.object,
  wishlist: PropTypes.array,
};

WebApp.defaultProps = {
  cart: undefined,
  menu: undefined,
  routes: undefined,
  theme: { settingsApp: {} },
  settings: {},
  settingsNextClaimingPeriod: undefined,
  settingsPointsUOM: undefined,
  settingsProgram: undefined,
  settingsSalesUOMs: undefined,
  wishlist: undefined,
};

const mapStateToProps = createStructuredSelector({
  cart: selectCart(),
  menu: selectMenu(),
  routes: selectRoutes(),
  settings: selectSettings(),
  settingsNextClaimingPeriod: selectSettingsNextClaimingPeriod(),
  settingsPointsUOM: selectSettingsPointsUOM(),
  settingsSalesUOMs: selectSettingsSalesUOMs(),
  settingsProgram: selectSettingsProgram(),
  wishlist: selectWishlist(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onCartRequest: () => dispatch(cartRequest()),
    onMenuRequest: refresh => dispatch(menuRequest(refresh)),
    onSettingsRequest: refresh => dispatch(settingsRequest(refresh)),
    onWishlistRequest: () => dispatch(wishlistRequest()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'webApp', reducer });
const withSaga = injectSaga({ key: 'webApp', saga });

export default compose(withTheme, withReducer, withSaga, withConnect)(WebApp);
