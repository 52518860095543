import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { css, up, styled, thd } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import { withSettings } from 'containers/WebApp/SettingsContext';

const FooterWrapper = styled.footer`
  background-color: ${thd('secondary', 'grey')};
  border-top: 12px solid ${thd('primary', 'gold')};
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 2rem 0 1rem 0;
  ${up(
    'md',
    css`
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 2rem;
    `
  )};
  p {
    color: #fff;
    font-size: 16px;
  }
  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${up(
      'md',
      css`
        flex-direction: row;
      `
    )};
  }
  li {
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    ${up(
      'md',
      css`
        //border-right: 1px solid #fff;
        padding: inherit;
        text-align: left;
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: #fff;
      font-weight: normal;
      text-decoration: none;
      padding: 0.1rem 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Footer = ({ siteName }) => (
  <FooterWrapper>
    <FooterContainer>
      <ul>
        <PrivateComponent>
          <li>
            <Link to="/faqs">FAQs</Link>
          </li>
        </PrivateComponent>
        <li>
          <Link to="/terms">Terms & Conditions</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
      </ul>
    </FooterContainer>
  </FooterWrapper>
);

Footer.propTypes = {
  siteName: PropTypes.string,
};

Footer.defaultProps = {
  siteName: 'MAX Rewards',
};

export default withSettings(Footer);
